import React, { useEffect, useState } from 'react';

import {
  AllFilterPosition,
  Filter,
} from 'libs/brand-system/src/components/Filter';
import { CardsList } from 'libs/brand-system/src/templates/CardsList';
import { useFilter } from 'libs/brand-system/src/utils/useFilter';

import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { BlogCardLayout } from 'libs/growth-platform-brand-system-v2/src/layouts/BlogCardLayout';
import {
  textBody,
  textTitle1,
} from 'libs/growth-platform-brand-system-v2/src/styles/typography';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { morphism } from 'morphism';
import { schemaBlogCardLayout } from '../../../morphismSchema/layouts/schemaBlogCardLayout';
import { schemaCardButtonLink } from '../../../morphismSchema/schemaCardButtonLinkProps';
import { getDateDisplay, getEventTypes, getEvents, isUpcoming } from './logic';

const EVENT_COLUMNS = 3;

const EventCard = ({ event, locale, index }: any) => {
  const screenSize = useScreenSize();
  const shouldDisplayCta = isUpcoming(event.endDate) || event.keepShowingCta;

  const { button, ..._linkProps } = morphism(schemaCardButtonLink, {
    ctaButton: {
      url: event.url,
      title: event.linkTitle,
    },
    isClientLink: false,
    params: '',
    screenSize,
    locale,
  });

  const linkProps = shouldDisplayCta && _linkProps;

  const eventProps = morphism(schemaBlogCardLayout, {
    linkProps: linkProps,
    subtitle: event.subtitle,
    image: event?.image,
    imgPosition: 'top',
    title: event.title || event?.linkTitle,
    logo: event.logos?.[0],
    tags: [
      {
        title: event.eventType.singular,
        isDark: true,
        size: 'small',
        color: event.color,
      },
      event.subject && {
        title: event.subject,
        size: 'small',
        color: event.color,
      },
    ],
    ...linkProps,
  });

  const { component, ...btnProps } = button.props;

  return (
    <div className={'EventCard px-12 w-full lg:py-36 py-12'}>
      <ButtonTracking
        component={BlogCardLayout}
        section="eventList"
        index={index}
        to={linkProps?.linkAttributes?.to}
        href={linkProps?.linkAttributes?.href}
        {...eventProps}
        ctaButton={
          button?.props && shouldDisplayCta ? (
            <ButtonV2 {...btnProps} className="text-21" />
          ) : undefined
        }
        capsText={getDateDisplay(event.startDate, event.endDate, locale)}
        hasSubtitleHover={true}
        hasTransparentBorders={true}
        labelTracking={btnProps.title}
      />
    </div>
  );
};

const EventList = ({
  datoEvents,
  locale,
  upcomingEvents,
  pastEvents,
  listTitle,
  displayTypeFilter,
  displayStatusFilter,
  initialRowSize,
  nextRowSize,
  seeMoreLabel,
  noEventFoundText,
  eventStatus,
  eventTypes,
}: any) => {
  const eventTypesAvailable = getEventTypes(datoEvents);
  const events = getEvents(datoEvents, locale);
  const eventStatusAvailable = [upcomingEvents, pastEvents];
  const [displayedEvents, setDisplayEvents] = useState(events);
  const [eventsSelected, setSelectedEvents] = useFilter();
  const [statusSelected, setSelectedStatus] = useFilter();
  const isDatePast = (date: Date) => new Date(date) < new Date();
  useEffect(() => {
    const filteredEvents = events.filter((event: any) => {
      const typeFilter =
        eventsSelected.length === 0 ||
        eventsSelected.includes(event.eventType.plural);
      let statusFilter = false;
      if (statusSelected.length === 0) statusFilter = true;
      else {
        statusFilter =
          (statusSelected.includes(upcomingEvents) &&
            isUpcoming(event.endDate)) ||
          (statusSelected.includes(pastEvents) && isDatePast(event.endDate));
      }
      return typeFilter && statusFilter;
    });

    setDisplayEvents(filteredEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsSelected, statusSelected]);

  const eventCards = displayedEvents.map((event: any, key: any) => (
    <EventCard event={event} key={key} locale={locale} index={key} />
  ));

  return (
    <section className={`relative bg-blue-21 pt-104`}>
      <div className="flex justify-center mb-40">
        {listTitle && (
          <h2 className={classNames(textTitle1, 'text-center md:w-1/2')}>
            {listTitle}
          </h2>
        )}
      </div>
      <div
        className={
          'flex justify-center flex-col sm:flex-row items-center gap-16'
        }
      >
        {eventTypes.length >= 2 && displayTypeFilter && (
          <Filter
            availableFilters={eventTypesAvailable}
            buttonTitle={eventTypes}
            locale={locale}
            selectedFilters={eventsSelected}
            onFilterClick={setSelectedEvents}
            zIndex={20}
          />
        )}
        {displayStatusFilter && (
          <Filter
            availableFilters={eventStatusAvailable}
            buttonTitle={eventStatus}
            locale={locale}
            selectedFilters={statusSelected}
            onFilterClick={setSelectedStatus}
            allButtonPosition={AllFilterPosition.END}
            sortAlphabeticalOrder={false}
          />
        )}
      </div>
      {eventCards.length > 0 ? (
        <CardsList
          id={'event'}
          cards={eventCards}
          backgroundColor="blue"
          buttonType="minimal"
          shouldDisplay={initialRowSize * EVENT_COLUMNS}
          seeMoreBatchSize={nextRowSize * EVENT_COLUMNS}
          seeMoreButtonLabel={seeMoreLabel}
        />
      ) : (
        <section className="container text-center pt-24 pb-48 sm:pb-64">
          <p className={textBody}>{noEventFoundText}</p>
        </section>
      )}
    </section>
  );
};

export default EventList;
